<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "HSE",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  methods: {
    fetchControlstypes(){
      this.$http
        .post("/system/misc/fetchControlsTypes")
        .then((res) => (this.types = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    }
  },
  mounted() {
    this.fetchControlstypes();
  },
  data() {
    return {
      title: "HSE",
      types: [],
      items: [
        {
          text: "HSE",
          href: "/requests",
        },
        {
          text: "HSE",
          active: true,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div
        class="col-lg-3"
        v-for="control in types"
        v-bind:key="control.name"
      >
        <router-link :to="{ name: 'ops.hse.index.category', params: { category: control.id }, }">
          <div class="card">
            <img
              class="card-img img-fluid"
              :src="
                require('@/assets/images/base/controls/' +
                  control.id +
                  '.jpg')
              "
              :alt="control.name"
              :title="control.name"
            />
            <div class="card-body">
              <h5 class="card-title text-center">{{ control.name }}</h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>